<template>
  <div class="step-images">
    <h2>Guia de como excluir sua conta do DMC Vivo:</h2>
    <div class="row items-center step">
      <div class="col-auto">
        <img
          src="../../../public/img/login-page-credentials.png"
          alt="Step 1"
        >
        <img
          src="../../../public/img/login-page-enter.png"
          alt="Step 2"
        >
      </div>
      <div class="col">
        <h3>
          <strong>Passo 1:</strong> Acessando o aplicativo
        </h3>
        <br>
        <p>
          Abra o aplicativo e insira suas credenciais de acesso.
          <br>
          Após inserir suas credenciais, clique em "Entrar".
        </p>
      </div>
    </div>

    <div class="row items-center step">
      <div class="col">
        <h3>
          <strong>Passo 2:</strong> Excluindo a conta
        </h3>
        <br>
        <p>
          Com o aplicativo aberto e logado, clique nos 3 pontinhos no canto superior direito da tela.
          <br>
          Arraste a tela para baixo e clique na opção de "Excluir cadastro".
        </p>
      </div>
      <div class="col-auto">
        <img
          src="../../../public/img/initial-page.png"
          alt="Step 1"
        >
        <img
          src="../../../public/img/more-page.png"
          alt="Step 2"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction'
}
</script>

<style scoped>
.step-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 1000px;
  justify-content: space-between;
}

.step img {
  width: 200px;
  height: 410px;
  margin-right: 20px;
}

.step p {
  font-size: 18px;
}
</style>
